.profile-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  justify-content: space-between;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; // Mozila
  align-items: center;

  .profile-content-container {
    max-width: 500px;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      .image-handling-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 200px;
        .profile-pic-upload-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 200px;
          width: 200px;
          input[type="file"] {
            display: none;
          }
          .img-wrap {
            cursor: pointer;
            position: relative;
            overflow: hidden;
            height: 200px;
            width: 200px;
            border-radius: 100px;
            .img-preview {
              object-fit: cover;
              display: inline;
              height: 200px;
              width: 200px;
            }
            .img-preview:hover {
              opacity: 0.5;
            }
          }
          .edit-image-section {
            display: flex;
            position: absolute;
            bottom: 0px;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 25px;
            background-color: #000000;
            opacity: 0.75;
          }
        }
      }

      .data {
        box-sizing: border-box;
        margin: 20px 0px;
        padding: 0px 20px;
        width: 100%;
      }
    }
  }
}
