.holiday-mode-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  scrollbar-width: none; // Mozila
  align-items: center;
  height: 100vh;

  .holiday-mode-content-container {
    flex-direction: column;
    max-width: 500px;
    width: 100%;

    .content {
      flex-direction: column;
      width: 100%;

      .option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px 10px 20px;
        .option-text {
          cursor: pointer;
        }
      }

      .title {
        display: flex;
        font-weight: normal;
        margin: 20px;
      }

      .goals-container {
        height: calc(100vh - 210px) !important;
        flex-direction: column;
        overflow-y: scroll;
        margin: 0px 10px;
        padding-bottom: 20px;
        .goal {
          display: flex;
          flex-direction: row;
          flex: 1;
          justify-content: space-between;
          min-height: 30px;
          .goal-name {
            cursor: pointer;
            display: flex;
            align-items: center;
            flex: 1;
            text-align: left;
            padding: 10px 0px;
          }
          .goal-tag {
            display: flex;
            width: 5px;
            border-radius: 50px;
          }
        }
      }
      .no-goals-container {
        margin: 0px 10px;
      }
    }
  }
}
