.edit-chat-message-modal {
  .modal-title {
    display: flex;
    justify-content: center;
    .modal-title-label {
      font-size: 24px;
      font-weight: bold;
    }
  }

  // Modal footer
  .btn-footer-container {
    display: flex;
    flex-direction: row;
    .btn {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
}
