.collapsible-divider-and-btn-container {
  margin: 0px 35px;
}

.collapsible-targets-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0px;
  .counter-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .counter-label {
      cursor: pointer;
      font-size: 14px;
    }
  }
}

.items-container {
  padding-left: 0px;
  margin: auto;
  max-width: 800px;

  .unsubmitted-month {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding: 10px 0px;
  }
  .submitted-month-title-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
    .submitted-month {
      font-weight: bold;
    }
  }

  .btn-submit-container {
    display: flex;
    justify-content: center;
    margin: 0px 0px 25px 0px;
    padding: 10px 0px;
  }

  .item-container {
    display: flex;
    margin: 0px 30px;

    .item:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .item {
      display: flex;
      flex: 1;
      min-height: 30px;
      align-items: center;
      background-color: transparent;

      .item-name-container {
        display: flex;
        flex: 1;
        // input[type="text"]:hover {
        //   text-decoration: underline;
        // }
      }

      // Icons/indicators (description, repeat, reminder)
      .item-icons-container {
        display: flex;
        height: 100%;
        align-items: center;
        .icons-row {
          display: flex;
          flex-direction: row;
          .item-icon {
            font-size: 20px;
            margin: 0px 5px;
          }
        }
      }

      .item-tag {
        width: 5px;
        height: 100%;
        border-radius: 40px;
      }
    }
  }

  // Coach and user comments and images
  .month-submitted-content-container {
    padding: 0px 20px;

    .messageSentContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      margin-right: 30px;
    }
    .messageReceivedContainer {
      display: flex;
      margin-top: 20px;
      margin-left: 30px;
    }
    // Images preview
    .images-preview-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-right: 20px;
      padding: 12px;
      .img-wrap {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        height: 75px;
        width: 75px;
        border-radius: 7px;
        margin: 2px;
        .image-preview {
          object-fit: cover;
          display: inline;
          height: 100%;
        }
      }
    }
  }
}
