.settings-shortcuts-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  justify-content: space-between;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; // Mozila
  align-items: center;

  .shortcuts-content-container {
    max-width: 500px;
    width: 100%;

    .content {
      margin-top: 20px;
      width: 100%;

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 15px;

        .badge {
          display: flex;
          align-items: center;
          .badge-label {
            background-color: gray;
            color: white;
            font-weight: bold;
            border-radius: 10px;
            font-size: 10px;
            padding: 5px;
          }
        }
      }
    }
  }
}
