// Repeat option checkboxes
.repeat-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  .repeat-option-text {
    cursor: pointer;
    padding-left: 10px;
  }
}

// Days of the week
.days-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .day-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .repeat-day-text {
      cursor: pointer;
    }
  }
}

// Roll over
.roll-over-container {
  display: flex;
  align-items: center;
}

// Ends
.ends-container {
  display: flex;
  flex-direction: column;
  .ends-title {
    font-weight: bold;
    margin: 10px 0px;
  }
  .ends-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0px;
  }
  .ends-option-text {
    cursor: pointer;
    display: flex;
    flex: 1;
    padding-left: 10px;
  }
  .custom-ends-picker-container {
    display: flex;
    align-self: flex-end;
  }
}

// Footer
.recurrence-pattern-picker-modal-footer {
  display: flex;
  flex-direction: row;

  .btn {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}
