.sorting-goals-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; // Mozila
  align-items: center;

  .sorting-goals-content-container {
    max-width: 500px;
    width: 100%;

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 50px;

      .title {
        display: flex;
        font-weight: normal;
        align-self: center;
      }

      .option {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 10px;
        .option {
          margin: 15px;
        }
        .repeat-option-text {
          cursor: pointer;
        }
      }
    }
  }
}
