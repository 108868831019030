.tooltip-text {
  position: absolute;
  top: 0px;
  left: -105px;
  width: 50px;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  overflow: "hidden";
  text-align: "center";
}
.tooltip-arrow {
  width: 0;
  height: 0;
  position: absolute;
  top: 3px;
  left: -27px;
  border-top: 0px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid;
  border-left: 10px solid transparent;
}
