// TODO//WARNING! - GLOBAL STYLE FOR ALL DATE TIME PICKER COMPONENTS. Move to somewhere that's global
.react-datepicker__month--selected {
  background-color: #6ab04c;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover {
  background-color: #548c3c;
}

// Styles
.month-calendar-picker-container {
  .modal-title {
    display: flex;
    justify-content: center;
    .modal-title-label {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .monthCalendarPicker-container {
    display: flex;
    justify-content: center;
  }
}
