.tutorial-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
      text-align: center;
      font-size: 20px;
      max-width: 350px;
    }
    .coach-img-container {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;
      width: 65px;
      border-radius: 50px;
      margin: 20px 10px 3px 5px;
      .coach-img {
        object-fit: cover;
        display: inline;
        width: 65px;
        height: 65px;
      }
    }
  }
  .starting-goals-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 500px;
    .top-image {
      display: flex;
      justify-content: center;
      .repeat-options-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0px;
        margin: 0px;
        // max-height: calc(100vh - 200px);
        overflow-y: scroll;
        scrollbar-width: none; // Mozila
        .repeat-option {
          cursor: pointer;
          width: 100%;
          border-radius: 6px;
          margin-top: 5px;
          .repeat-option-text {
            padding: 0px 10px;
          }
        }
      }
      .addItemContainer {
        display: flex;
        flex: 1;
        align-self: center;
        justify-content: center;
        .addItemInputContainer {
          max-width: 300px;
          padding: 20px;
          border-radius: 20px;
          .addItemInputAndSendContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 10px 0px;
          }
          .addItemText {
            display: flex;
            align-items: center;
            font-size: 18px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    width: 300px;
    height: 50px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;
  }
}
