.goalsHeaderContainer {
  margin: 10px 35px;
  border-radius: 20px;
  .contentContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 25px;
    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .motivateMessage {
        font-size: 16px;
        padding: 5px 0px;
      }
      .motivateMessageDayAndName {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        padding: 5px 0px;
      }
    }
    .imageContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      margin: 10px 0px;
    }
    .logo-graph-container {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 80px;
      width: 80px;
      margin: 10px 0px;
    }
    .coachImage {
      border-radius: 50px;
    }
  }
}
