.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0px;
}

.round {
  position: relative;
  width: 22px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.tag-picker-modal-footer {
  display: flex;
  flex-direction: row;
}
