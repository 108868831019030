.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // height: 85px; // not working after adding chat content
  -moz-box-shadow: 4px 0 3px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 4px 0 3px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 0px 3px 1px rgba(0, 0, 0, 0.3);
  z-index: 20;

  .headerButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    // BORDER FOR STATUS ALTERNATIVE 1
    // .coach-img-container {
    //   position: relative;
    //   overflow: hidden;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   height: 65px;
    //   width: 65px;
    //   border-radius: 50em;
    //   border: 4px solid transparent;
    //   margin: 3px 10px 3px 5px;
    //   .coach-img {
    //     object-fit: cover;
    //     display: inline;
    //     width: 65px;
    //     height: 65px;
    //   }
    // }

    // BORDER FOR STATUS ALTERNATIVE 2
    .coach-img-container {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;
      width: 65px;
      border-radius: 50em;
      border: 4px solid transparent;
      margin: 3px 10px 3px 5px;
    }

    // ANIMATED BORDER
    // // Same class but for dark mode and light mode to work around background property 🤷‍♂️
    // .coach-img-container-with-border-light-mode,
    // .coach-img-container-with-border-dark-mode {
    //   position: relative;
    //   overflow: hidden;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   height: 65px;
    //   width: 65px;
    //   border-radius: 50em;
    //   border: 4px solid transparent;
    //   margin: 2px 10px 2px 5px;
    // }
    // .coach-img-container-with-border-light-mode::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   padding: 4px; /* Adjust padding to control the width of the border */
    //   border-radius: 50em;
    //   border: 4px solid transparent;
    //   background: linear-gradient(#f3f3f3, #f3f3f3) padding-box,
    //     linear-gradient(to right, #fb470c, #fbce02) border-box;
    //   z-index: -1;
    //   animation: rotate 1.5s linear infinite; /* Animation properties */ /* Speed */
    // }
    // .coach-img-container-with-border-dark-mode::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   padding: 4px; /* Adjust padding to control the width of the border */
    //   border-radius: 50em;
    //   border: 4px solid transparent;
    //   background: linear-gradient(#121212, #121212) padding-box,
    //     linear-gradient(to right, #fb470c, #fbce02) border-box;
    //   z-index: -1;
    //   animation: rotate 1.5s linear infinite; /* Animation properties */ /* Speed */
    // }

    // @keyframes rotate {
    //   from {
    //     transform: rotate(0deg);
    //   }
    //   to {
    //     transform: rotate(360deg);
    //   }
    // }

    .coach-img {
      object-fit: cover;
      display: inline;
      width: 100%; // 90% for ANIMATED BORDER
      height: 100%; // 90% for ANIMATED BORDER
      border-radius: 50%;
    }
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .coachName {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }
  .textExtra {
    cursor: pointer;
  }

  .star-btn-container {
    margin-right: 10px;
  }
}
