.nominate-supporter-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; // Mozila
  align-items: center;

  .nominate-supporter-content-container {
    max-width: 500px;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      width: 100%;

      .data {
        box-sizing: border-box;
        margin: 0px 20px;
      }

      .personal-option {
        display: flex;
        justify-content: space-between;
        margin: 0px 20px 30px 20px;
      }

      .title {
        display: flex;
        font-weight: normal;
        align-self: center;
      }

      .option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 20px;
        .repeat-option-text {
          cursor: pointer;
        }
      }
    }
  }
}
