// TODO//WARNING! - GLOBAL STYLE FOR ALL DATE TIME PICKER COMPONENTS. Move to somewhere that's global
.react-datepicker__day--selected {
  background-color: #6ab04c;
}

.react-datepicker__day--selected:hover {
  background-color: #548c3c;
}

// Styles
.modal-title {
  display: flex;
  justify-content: center;
}

.modal-title-label {
  font-size: 24px;
  font-weight: bold;
}

.shortcuts-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.shortcut-btn {
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 80px;
  border: transparent;
  border-radius: 50px;
  margin: 0px 5px;
}

.shortcut-icon {
  cursor: pointer;
  font-size: 30px;
}

.shortcut-label {
  font-size: 12px;
  cursor: pointer;
}

.datePicker-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.undated-goals-shortcut-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .undated-goals-btn-container {
    display: flex;
    width: 80%;
    justify-content: center;
    .undated-goals-shortcut-btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      border: transparent;
      border-radius: 5px;
    }
  }
}
