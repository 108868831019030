.chat-section {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 400px;
  max-width: 28vw;
  flex-direction: column;
  min-height: 0;
  transition: 0.5s ease;

  .chat-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
    scrollbar-width: none; // Mozila
    min-height: 0;
    padding: 5px;

    .overlay {
      backdrop-filter: blur(
        5px
      ); //* Apply the backdrop filter to the chat content */
      transition: filter 0.5s ease; //* Add a transition for smooth effect */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1; /* Place the overlay above other elements */
      pointer-events: none; /* Allows pointer events to go through the overlay */
    }
    .sending-message-loader {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
    .no-messages-yet {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
    .loading-more-messages-container {
      display: flex;
      justify-content: center;
      margin: 10px 0px;
    }
    .no-more-messages-text {
      padding: 5px 10px;
      border-radius: 40px;
    }

    // Scroll to bottom
    .scrollToBottomButtonContainer {
      position: absolute;
      right: 7px;
      margin-bottom: 20px;
      z-index: 2; // To be in front of the chat message bubble options
    }

    .dateOfDay {
      display: flex;
      align-self: center;
      margin: 15px 0px;
    }
  }
  .arrow-btn {
    position: absolute;
    bottom: 15%;
    left: -15px;
    z-index: 997; // To bring in front of everything but, for now, image modal
    cursor: pointer;
    .btn-toggle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: none;
      outline: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

@media screen and (min-width: 1440px) {
  .chat-section {
    max-width: 450px;
  }
}

@media screen and (max-width: 768px) {
  .chat-section {
    width: 75%;
    max-width: unset !important;
  }
}
@media screen and (max-width: 600px) {
  .chat-section {
    width: 100%;
  }
}

.chat-wrap {
  max-width: 56px;
  position: relative;
  .chat-icon {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 15px;
    width: 100%;
  }
}

// MOBILE CHAT
// .deleteImageIcon {
//   position: "absolute";
//   top: 1;
//   right: 1;
// }
