//////////// Header ////////////
.section-title {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
  font-weight: bold;
  font-size: 18px;
}

//////////// Body ////////////
.options-container {
  display: flex;
  flex-direction: column;
  .option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
    .ends-option-text {
      cursor: pointer;
    }
  }
}

//////////// Footer ////////////
.delete-recurring-goal-footer {
  display: flex;
  justify-content: center;
}
