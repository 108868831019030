.edit-target-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 3;
  align-items: center;
  min-height: calc(100vh - 69px) !important;
  scrollbar-width: none; // Mozila

  .edit-target-content-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;

    // Body
    .body {
      display: flex;
      flex-direction: column;
      // Edit buttons
      .edit-btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 15px;
        height: 40px;

        .edit-btns-first-section {
          display: flex;
          flex-direction: row;
          align-items: center;

          .divider {
            margin: 5px 15px;
          }
        }

        .btn-edit {
          cursor: pointer;
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          font-size: 20px;

          .btn-edit-date {
            cursor: pointer;
            font-size: 16px;
            margin: 0px 5px;
          }
        }
      }

      .roll-over-container {
        display: flex;
        align-items: center;
        margin: 10px;
      }

      .title-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        margin: 20px;
        .target-tag {
          height: 100%;
          width: 5px;
          border-radius: 10px;
        }
      }

      // Inputs
      .goal-name,
      .goal-name:hover,
      .goal-name:active,
      .goal-name:visited,
      .goal-name:focus {
        width: 100%;
        background: transparent;
        outline: none;
        border-width: 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        font-weight: bold;
        font-size: 25px;
      }

      .goal-description-section {
        margin: 20px 20px;
        .goal-description-title {
          font-weight: bold;
        }
      }
    }
  }
}
