.sentBubbleContainer {
  position: relative;
  padding: 15px;
  border-radius: 20px;
  max-width: 60%;
  white-space: pre-wrap;

  .sentMessage {
    font-size: 16px;
  }
  .subjective-well-being-rate {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .leftArrow {
    position: absolute;
    width: 20px;
    height: 25px;
    bottom: 0px;
    right: -10px;
    border-bottom-left-radius: 25px;
  }
  .leftArrowOverlap {
    position: absolute;
    width: 20px;
    height: 35px;
    bottom: -6px;
    right: -20px;
    border-bottom-left-radius: 18px;
  }
}

.receivedBubbleContainer {
  position: relative;
  padding: 15px;
  border-radius: 20px;
  white-space: pre-wrap;

  .receivedMessage {
    font-size: 16px;
  }
  .rightArrow {
    position: absolute;
    width: 20px;
    height: 25px;
    bottom: 0px;
    left: -10px;
    border-bottom-right-radius: 25px;
  }
  .rightArrowOverlap {
    position: absolute;
    width: 20px;
    height: 35px;
    bottom: -6px;
    left: -20px;
    border-bottom-right-radius: 18px;
  }
}
