.video-modal-container {
  display: flex;
  justify-content: center;
  border: 3px solid #fff;

  .video {
    max-width: 100%;
    max-height: 90vh;
  }
}
