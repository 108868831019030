.modal-title {
  text-align: center;
}

.coach-info-overlay-content {
  margin: 0px 25px 25px 25px;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
  }
  .subtitle {
    text-align: center;
    margin: 0px 0px 15px 0px;
    font-size: 16px;
    white-space: pre-wrap;
  }
  .text-title {
    display: flex;
    margin-top: 18px;
    font-size: 16px;
    font-weight: bold;
  }
  .text {
    display: flex;
    font-size: 16px;
    white-space: pre-line;
    margin-top: 5px;
  }
}

.coach-img-and-info-modal-container {
  .coach-img-container {
    width: 100%;
    padding-top: 56.25%; // 16:9 aspect ratio (9 / 16 * 100%)
    position: relative;
    overflow: hidden;
    .coach-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2; // Ensure it's displayed above the image
    }
  }

  .coach-img-container-no-img {
    height: 45px;
  }

  .name-and-bio-container {
    border-radius: 5px;
    position: relative;
    margin-top: -25px; // Move the container up by 50px
    z-index: 2; // Ensure it's displayed above the image
    padding: 20px;
    margin-left: 20px; // Adjust margins as needed
    margin-right: 20px; // Adjust margins as needed
    .coach-name {
      font-size: 18px;
      font-weight: bold;
    }
    .coach-description-container {
      margin-top: 10px;
      .coach-description {
        font-size: 16px;
      }
    }

    .coach-status-img-container {
      cursor: pointer;
      position: absolute;
      top: -25px; // -20px for ANIMATED BORDER
      right: -5px;
      // Same class but for dark mode and light mode to work around background property 🤷‍♂️
      .coach-status-img-container-with-border-light-mode,
      .coach-status-img-container-with-border-dark-mode {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65px;
        width: 65px;
        border-radius: 50em;
        border: 4px solid transparent;
        margin: 2px 10px 2px 5px;
        .coach-status-img {
          object-fit: cover;
          display: inline;
          width: 100%; // 90% for ANIMATED BORDER
          height: 100%; // 90% for ANIMATED BORDER
          border-radius: 50%;
        }
      }
      // ANIMATED BORDER
      // .coach-status-img-container-with-border-light-mode::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   padding: 4px; /* Adjust padding to control the width of the border */
      //   border-radius: 50em;
      //   border: 4px solid transparent;
      //   background: linear-gradient(#f3f3f3, #f3f3f3) padding-box,
      //     linear-gradient(to right, #fb470c, #fbce02) border-box;
      //   z-index: -1;
      //   animation: rotate 1.5s linear infinite; /* Animation properties */
      // }
      // .coach-status-img-container-with-border-dark-mode::before {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   padding: 4px; /* Adjust padding to control the width of the border */
      //   border-radius: 50em;
      //   border: 4px solid transparent;
      //   background: linear-gradient(#121212, #121212) padding-box,
      //     linear-gradient(to right, #fb470c, #fbce02) border-box;
      //   z-index: -1;
      //   animation: rotate 1.5s linear infinite; /* Animation properties */
      // }

      // @keyframes rotate {
      //   from {
      //     transform: rotate(0deg);
      //   }
      //   to {
      //     transform: rotate(360deg);
      //   }
      // }
    }
  }
}

// Modal Footer
.modal-footer {
  cursor: pointer;
  justify-content: center;
}
