.plans-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: space-between;

  .plans-content-container {
    max-width: 500px;
    width: 100%;

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
  }
}
