.manage-profile-picture-modal {
  .modal-title {
    display: flex;
    justify-content: center;
    .modal-title-label {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .btn-footer-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
  }
}
