.date-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px 0px;
}

// Shortcuts
.shortcuts-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}
.shortcut-btn {
  display: flex;
  flex-direction: column;
  width: 100px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: transparent;
}
.shortcut-icon {
  font-size: 30px;
}
.shortcut-label {
  font-size: 12px;
  cursor: pointer;
}

// Custom input
.custom-date-input-container {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}
// .custom-date-input,
// .custom-date-input:active,
// .custom-date-input:visited {
//   background-color: transparent;
//   border: solid;
//   border-width: 1px;
//   border-radius: 10px;
//   border-color: $primary;
//   color: $primary;
//   padding: 7px;
// }

// Remove date
.remove-date-btn-label {
  cursor: pointer;
  margin-left: 5px;
}

// Footer
.modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0px !important;
}
