.chatMessageSentContainer {
  display: flex;
  align-self: flex-end;
  max-width: 75%;

  /* Ensure long text breaks within the bubble */
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Fallback for older browsers */
  overflow-wrap: break-word; /* Modern equivalent for breaking words */
  white-space: pre-wrap; /* Preserve whitespace and line breaks, but allow wrapping */
  .subjective-well-being-scale {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .chatMessageSentBubbleContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    position: relative; // So reaction can use an absolute position to be place at bottom left of bubble
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 3px;
    max-width: 100%; /* Constrain bubble to parent width */
    margin: 3px 4px 3px 0px;
    padding: 10px;
    box-sizing: border-box; /* Include padding and borders in the width calculation */
  }
  .chatMessageSentBubbleContainer:hover .show-message-options-btn-on-hover {
    display: inline;
  }
  .alert-icon-container {
    display: flex;
    align-items: center;
  }
}
.chatMessageSentReactionsContainer {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  position: absolute;
  bottom: -15px;
  height: 25px;
  z-index: 0;
  border: none;
}
.chat-message-sent-reactions-option-container {
  position: absolute;
  top: -44px;
  right: 0px;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 5px;
}

.chatMessageReceivedBubbleContainer {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative; // So reaction can use an absolute position to be place at bottom left of bubble
  border-top-left-radius: 10px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 3px 0px 3px 4px;
  max-width: 100%; /* Constrain bubble to parent width */
  padding: 10px;
  max-width: 75%;
  box-sizing: border-box; /* Include padding and borders in the width calculation */

  /* Ensure long text breaks within the bubble */
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Fallback for older browsers */
  overflow-wrap: break-word; /* Modern equivalent for breaking words */
  white-space: pre-wrap; /* Preserve whitespace and line breaks, but allow wrapping */
}
.chatMessageReceivedBubbleContainer:hover .show-message-options-btn-on-hover {
  display: inline;
}
.chatMessageReceivedReactionsContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: -15px;
  height: 25px;
  z-index: 0;
  border: none;
}
.chat-message-received-reactions-option-container {
  position: absolute;
  top: -44px;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 5px;
  right: 0px;
}

.emoji-option-container {
  width: 25px;
  height: 25px;
}
.emoji-option-text {
  font-size: 20px;
  color: #000000;
}

.reaction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 25px;
  border-radius: 10px;
  margin-right: 3px; // To separate from aside reaction
}
.reaction-text {
  font-size: 20px;
}

.message-replied-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin: 0px 0px 10px 0px;
  padding: 7px;
  border: solid;
  border-width: 0px;
  border-left-width: 5px;
  .message-sender {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-bottom: 5px;
    .replied-message-loader {
      padding-left: 5px;
    }
  }
  .message-text {
    cursor: pointer;
    display: block;
    line-height: 1.5em;
    max-height: 6em;
    overflow: hidden;
    font-size: 14px;
  }
}

.chat-message-text {
  overflow: hidden;
}

.message-img-wrap {
  position: relative;
  overflow: hidden;
  .message-with-image {
    cursor: pointer;
    object-fit: cover;
    display: inline;
    height: 180px;
    width: 180px;
    border-radius: 8px;
  }
  .replied-image {
    cursor: pointer;
    object-fit: cover;
    display: inline;
    height: 100px;
    width: 100px;
    border-radius: 5px;
  }
  .play-icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.message-video-wrap {
  .message-with-video {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    object-fit: cover;
    height: 180px;
    width: 180px;
    border-radius: 8px;
  }
  .replied-video {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 5px;
  }
}
.message-date-and-icons-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
  .messageDate {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    text-align: right;
  }
}

// Message options
.show-message-options-btn-on-hover {
  display: none;
  position: absolute;
  top: 1px;
  right: 1px;
}
.message-sent-options-container {
  position: absolute;
  right: 10px;
  z-index: 2; // To bring to front of chat
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.message-received-options-container {
  position: absolute;
  right: -40px;
  z-index: 2; // To bring to front of chat
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
