.inboxes-screen-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  outline: none;
  height: 100vh;
  align-items: center;

  .inboxes-screen-content-container {
    position: relative;
    max-width: 800px;
    width: 100%;

    .title-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;
    }

    .inboxes-screen-scroll-section-container {
      // overflow-y: scroll;
      scrollbar-width: none; // Mozila
      // max-height: calc(100vh - 140px) !important; // TODO - 210 for mobile

      // Spinner
      .loading-spinner-container {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
      .loading-spinner {
        margin-top: 150px;
      }

      // No goals for the day
      .no-goals-container {
        display: flex;
        justify-content: center;
        margin: 75px 30px;
        .no-goals-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 300px;
          .no-goals-title {
            font-weight: bold;
          }
          .no-goals-text {
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .float-btns-container {
    max-width: 800px;
    width: 100%;
  }
}
