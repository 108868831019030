.dataInput-container {
  // margin: 10px 30px;
  // position: absolute;
  left: 0;
  right: 0;
  // bottom: 0px;
  padding: 0px 35px 30px 35px;
  margin: 0px;
  z-index: 996; // Fix for Simon not being able to click input but goal behind it

  .btns-container {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;

    .btn-container {
      padding: 0px 10px 0px 0px;
    }
  }
}
