.bottom-tab {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  z-index: 997; // // To bring in front of everything but, for now, image modal
  // box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.125);
  // border-top: 1px solid;

  a {
    text-decoration: none;
  }

  ul {
    padding-left: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    li {
      list-style: none;
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 13px 7px; // TODO - Make smaller, that will also change screens height
      span {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          height: 20px;
          width: 20px;
        }
        p {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }

  .coach-img {
    height: 40px;
    width: 40px;
  }
}
