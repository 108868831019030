.settings-screen-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  justify-content: space-between;
  align-items: center;
  scrollbar-width: none; // Mozila

  .settings-screen-content-container {
    max-width: 500px;
    width: 100%;

    ul {
      padding: 0px 10px;
      li {
        list-style: none;
        display: flex;
        height: 35px;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5px;
        border-bottom: 1px solid;

        .client-img-container {
          position: relative;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 65px;
          width: 65px;
          border-radius: 50px;
          margin: 3px 10px 3px 5px;
          .client-img {
            object-fit: cover;
            display: inline;
            width: 65px;
            height: 65px;
          }
        }

        .navigates-indicator {
          cursor: pointer;
          margin-right: 15px;
        }

        .new-badge {
          display: flex;
          align-items: center;
          .new-badge-span {
            background-color: red;
            color: white;
            font-weight: bold;
            border-radius: 10px;
            font-size: 10px;
            padding: 5px;
          }
        }
      }
    }

    .bottom-section {
      display: flex;
      flex-direction: column;

      .version {
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 10px;
      }
    }
  }
}
