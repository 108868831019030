.mainWrapper,
.mobile-wrapper {
  display: flex;
}

.product-wrapper {
  // height: calc(100vh - 70px) !important;
  width: 100%;
  // overflow: auto;
}
