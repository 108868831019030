.sidebar-wrapper {
  height: 100vh;
  position: relative;
  width: 275px;
  max-width: 275px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: 0.3s ease-in-out;

  a {
    text-decoration: none;
  }

  &.open {
    max-width: 56px;
    transition: 0.3s ease-in-out;
  }
  .sidebar-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 90px;
      height: auto;
      cursor: pointer;
      width: 90%;
      margin-top: 4px;
    }
  }
  .content-item-wrap {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item-list {
      ul {
        padding-left: 0;
        li {
          list-style: none;
          padding: 0 1rem;
          border: none;
          // border-bottom: 1px solid;
          line-height: 50px;
          cursor: pointer;
          span {
            text-decoration: none;
            display: flex;
            align-items: center;
            h5 {
              margin: 0px 0px 0px 10px;
              font-weight: 400;
              font-size: 18px;
              @media screen and (max-width: 768px) {
                font-size: 15px;
              }
            }
            &.MuiBadge-badge {
              right: 11px;
            }
          }
          &.active {
            span {
              transition: 0.5s ease;
              h5 {
                font-weight: 700;
              }
            }
          }
          &:hover {
            span {
              h5 {
                font-weight: 700;
                transition: 0.5s ease;
              }
            }
          }
          &.tab-device {
            display: none;
            // Max width until chat tab will be shown
            @media screen and (max-width: 768px) {
              display: flex;
            }
            span {
              h5 {
                transition: 0.5s ease;
                font-weight: 700;
              }
            }
          }
          &.tab-active-device {
            display: none;
            // Max width until chat tab will be shown
            @media screen and (max-width: 768px) {
              display: flex;
              justify-content: flex-start;
              &.active {
                display: flex;
                justify-content: center;
                span {
                  transition: 0.5s ease;
                  h5 {
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }
    }
    .setting-item {
      padding: 0 1rem;
      line-height: 50px;
      cursor: pointer;
      span {
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        display: flex;
        align-items: center;
      }
      &.active {
        span {
          font-weight: 700;
        }
        a {
          font-weight: 700;
          transition: 0.5s ease;
        }
      }
      &:hover {
        span {
          font-weight: 700;
          transition: 0.5s ease;
        }
        a {
          font-weight: 600;
          transition: 0.5s ease;
        }
      }
    }
  }
  .arrow-btn {
    position: absolute;
    bottom: 15%;
    right: -15px;
    z-index: 997; // To bring in front of everything but, for now, image modal
    cursor: pointer;
    .btn-toggle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: none;
      outline: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar-wrapper {
    max-width: 25%;
  }
}

@media screen and (min-width: 1440px) {
  .sidebar-wrapper {
    // max-width: 300px;
  }
}
