.sounds-settings-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; // Mozila
  align-items: center;

  .sounds-settings-content-container {
    max-width: 500px;
    width: 100%;

    ul {
      padding: 20px;
      li {
        list-style: none;
        display: flex;
        height: 35px;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5px;
        border: none;
        // border-bottom: 1px solid;

        .navigates-indicator {
          cursor: pointer;
          margin-right: 15px;
        }
      }
    }
  }
}
