.milestones-screen-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  outline: none;
  height: 100vh;
  align-items: center;

  .milestones-screen-content-container {
    position: relative;
    max-width: 800px;
    width: 100%;

    .title-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;
    }

    .milestones-header-container {
      display: flex;
      flex: 1;
      height: 120px;
      .milestones-header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        margin: 10px 35px;
        padding: 0px 30px;
        border-radius: 20px;
      }
    }

    .milestones-screen-scroll-section-container {
      overflow-y: scroll;
      scrollbar-width: none; // Mozila
      // max-height: calc(100vh - 200px) !important;

      // Spinner
      .loading-spinner-container {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
      .loading-spinner {
        margin-top: 150px;
      }

      .emoji {
        font-size: 46px;
      }
      .milestone-name {
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
        text-align: left;
        word-wrap: break-word;
      }

      .loading-more-milestones-container {
        display: flex;
        justify-content: center;
        margin: 10px 0px;
        .no-more-milestones-text {
          padding: 5px 10px;
          border-radius: 40px;
        }
      }
    }
  }
}
