.submit-month-overlay-container {
  .comment-container {
    display: flex;
    flex: 1;
    flex-direction: "row";
  }

  // Images preview
  .images-preview-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0px;
    .img-wrap {
      position: relative;
      overflow: hidden;
      height: 75px;
      width: 75px;
      border-radius: 7px;
      margin: 1.5px;
      .image-preview {
        object-fit: cover;
        display: inline;
        height: 100%;
      }
    }
    .img-wrap .close-preview-img {
      position: absolute;
      top: 5px;
      right: 5px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      display: none;
    }
    .img-wrap:hover .close-preview-img {
      background-color: #fff;
      display: inline;
    }
  }

  .btn-add-img-container {
    display: flex;
    justify-content: center;
    margin: 20px 0px 10px 0px;

    input[type="file"] {
      display: none;
    }
    .btn-add-image {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 75px;
      border-radius: 20px;
      .btn-add-image-icon {
        cursor: pointer;
      }
    }
    .images-limit-reached {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 75px;
      border-radius: 20px;
    }
  }

  .subjective-well-being-alert {
    display: flex;
    align-self: center;
    margin-top: 5px;
  }

  .subjective-well-being-scale {
    text-align: center;
    margin-top: 10px;
  }

  // Footer
  .submit-month-modal-footer {
    display: flex;
    flex-direction: "row";
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative; // So the absolutes inside are wrapped and don't get out
  }
}
