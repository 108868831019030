.status-modal-container {
  display: flex;
  justify-content: center;
  border: 3px solid #fff;

  .status-img {
    max-width: 100%;
    max-height: 90vh;
  }

  .status-text-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10px;
    border-radius: 5px;
    z-index: 2; // Ensure it's displayed above the image
    padding: 7px;
    margin-left: 10px;
    margin-right: 10px;
    .status-title {
      font-size: 18px;
      font-weight: bold;
    }
    .status-dateTime {
      margin: 5px 0px;
      font-size: 11px;
    }
  }
}
.status-modal-counter-container {
  position: fixed;
  top: 20px;
  right: 70px;
  z-index: 999;
}
