.modal-title-container {
  text-align: center;
}

.alert-overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .emoji {
    text-align: center;
    font-size: 16px;
  }
  .img {
    object-fit: cover ;
    border-radius: 5px,
  }
  .description {
    display: flex;
    font-size: 16px;
    white-space: pre-line;
    margin: 20px 0px;
  }
}

// Modal Footer
.modal-footer {
  cursor: pointer;
  justify-content: center;
}