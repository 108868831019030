.screen-header-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 20px 0px 30px 0px;

  .btn-back {
    position: absolute;
    left: 0;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    max-width: 60%;
  }
}
