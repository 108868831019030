.message-to-reply-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: solid;
  border-width: 0px;
  border-left-width: 5px;
  padding: 5px;
  padding-right: 7px;
  .message-content {
    display: flex;
    flex-direction: column;
    max-width: 85%;
    .message-sender {
      font-size: 15px;
      font-weight: bold;
      padding-bottom: 5px;
    }
    .img-wrap {
      position: relative;
      overflow: hidden;
      margin: 2px;
      .image-preview {
        object-fit: cover;
        display: inline;
        border-radius: 7px;
      }
      .play-icon-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .video {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75px;
      width: 75px;
      border-radius: 7px;
    }
    .message-text {
      display: block;
      line-height: 1.5em;
      max-height: 6em;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
    }
  }
}

.chatInput-container {
  display: flex;
  flex-direction: column;
  padding: 0px 5px;

  input[type="file"] {
    display: none;
  }

  // Images preview
  .images-preview-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0px;
    .img-wrap {
      position: relative;
      overflow: hidden;
      height: 75px;
      width: 75px;
      border-radius: 7px;
      margin: 2px;
      .image-preview {
        object-fit: cover;
        display: inline;
        height: 100%;
      }
    }
    .img-wrap .close-preview-img {
      position: absolute;
      top: 5px;
      right: 5px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      display: none;
    }
    .img-wrap:hover .close-preview-img {
      background-color: #fff;
      display: inline;
    }
  }

  // Input and buttons container
  .input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    // Buttons
    .add-files-icon {
      cursor: pointer;
    }
    .btn-add-message-container {
      cursor: pointer;
      background-color: transparent;
      border: none;
      margin: 0px 7px 0px 15px;
      padding: 0px;
    }
  }
}

.chatInput-container.highlight {
  outline: 2px dashed #6ab04c;
  outline-offset: -2px;
}
