.month-picker-modal {
  .months-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .month-btn {
      margin: 5px;

      .btn-month-text {
        cursor: pointer;
      }
    }
  }

  .month-picker-modal-footer {
    display: flex;
    flex-direction: row;
  }
}
