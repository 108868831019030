.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.auth-content-container {
  width: 311px;
  margin-top: 30px;
  .title {
    display: flex;
    align-self: flex-start;
    font-weight: bold;
    font-size: 26px;
  }
  .divider-container {
    margin: 25px 0px;
  }
}

.auth-inputs-container {
  margin: 15px 0px 20px 0px;
  .auth-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .adornment-container {
      margin: 35px 10px 10px 0px;
    }
  }
}

.btn-auth-container {
  margin: 10px 0px;
  .btn-auth-text {
    display: flex;
    justify-content: center;
  }
}

.btn-google-sign-in-label {
  display: flex;
  align-items: center;
}

// Nav buttons
.btns-auth-nav-container {
  margin-top: 40px;
  .btn-auth-nav-container {
    margin: 20px 0px;
    .clear_btn_text_1 {
      cursor: pointer;
    }
    .clear_btn_text_2 {
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.btn-auth-nav {
  text-decoration: none;
}
