.releases-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 400px;
    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-top: 25px;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .releases {
      height: 100vh;
      max-height: calc(100vh - 350px);
      overflow-y: scroll;
      scrollbar-width: none; // Mozila
      margin: 20px 0px;
      text-align: left;
      .release {
        display: flex;
        flex-direction: column;
        .release-version {
          align-self: center;
          font-size: 18px;
          margin-top: 25px;
          font-weight: bold;
        }
        .release-intro {
          margin-top: 10px;
          font-size: 18px;
        }
        .release-notes-container {
          margin-top: 10px;
          .release-note-container {
            margin: 4px 0px;
            .release-note {
              font-size: 18px;
            }
          }
        }
        .release-outro {
          margin-top: 10px;
        }
      }
    }

    .buttonText {
      cursor: pointer;
    }
  }
}
