.goals-screen-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  outline: none;
  height: 100vh;
  align-items: center;

  .goals-screen-content-container {
    position: relative;
    max-width: 800px;
    width: 100%;

    .title-navigation-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 20px 0px;
      height: 40px;

      .btn-nav-calendar {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        .title-day {
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
        }
        .title-date {
          cursor: pointer;
        }
      }

      .left-arrow-container {
        position: absolute;
        left: 0;
      }

      .right-arrow-container {
        position: absolute;
        right: 0;
      }

      .btn-nav-days {
        cursor: pointer;
        display: flex;
        vertical-align: center;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        background-color: transparent;
        border: none;
        color: black;
        padding: 15px 32px; // To make it easier to tap into
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        .btn-nav-days-text {
          cursor: pointer;
          font-size: 12px;
        }
      }
    }

    .goals-screen-scroll-section-container {
      scrollbar-width: none; // Mozila

      // Back to today button
      .back-to-today-button-container {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        // width: 300px;
        height: 90px;
        .back-to-today-button-text {
          font-size: 16px;
          padding: 0px 5px;
          cursor: pointer;
        }
      }

      // Spinner
      .loading-day-spinner-container {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
      .loading-day-spinner {
        margin-top: 150px;
      }

      // Holiday mode button
      .holiday-mode-btn-container {
        display: flex;
        flex: 1;
        margin: 5px 35px 0px 35px;
      }

      // No goals for the day
      .no-goals-container {
        display: flex;
        justify-content: center;
        margin: 75px 30px;
        .no-goals-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 300px;
          .no-goals-title {
            font-weight: bold;
          }
          .no-goals-text {
            text-align: center;
            margin-top: 10px;
          }
        }
      }

      // Coach and user comments and images
      .day-submitted-content-container {
        padding-bottom: 100px;

        .messageSentContainer {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          margin-right: 30px;
        }
        .messageReceivedContainer {
          display: flex;
          margin-top: 20px;
          margin-left: 30px;
        }

        // Images preview
        .images-preview-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-end;
          margin-right: 20px;
          padding: 12px;
          .img-wrap {
            cursor: pointer;
            position: relative;
            overflow: hidden;
            height: 75px;
            width: 75px;
            border-radius: 7px;
            margin: 2px;
            .image-preview {
              object-fit: cover;
              display: inline;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .float-btns-container {
    max-width: 800px;
    width: 100%;
  }
}
